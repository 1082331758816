import axios from 'axios';
import { Message } from 'element-ui'; // 引入Element UI的Message组件
import router from '@/router'; // 假设你有Vue Router
import store from '@/store'; // 假设你使用Vuex
import {showLoading, hideLoading }  from '@/services/tool/Loading'



const service = axios.create({
    baseURL: process.env.VUE_APP_API_URL, // 开发环境的API基础URL
    timeout: 50000,
    // ...其他自定义配置属性
    isShowMessage: true,//是否需要提示信息
    isShowLoading: true,//是否需要遮罩
    isAddToken: true,//是否需要添加token
});

// let timer;
// 请求拦截器
service.interceptors.request.use(
    config => {
        // 添加全局Token，假设从Vuex中获取
        // const token = store.getters.token;
        //开启遮罩
        if (config.isShowLoading){
            showLoading();
        }
        if (config.isAddToken) {
            let token=JSON.parse(localStorage.getItem("userInfo")).token;
            config.headers.Authorization = `${token}`;
        }
        return config;

    },
    error => {

        // 如果有错误，处理它，0.8s后关闭遮罩层
            setTimeout(() => {
                hideLoading();
            },800)

        console.error('请求拦截器错误:', error);
        return Promise.reject(error);
    }
);

// 响应拦截器
service.interceptors.response.use(
    res => {
        //0.8s后关闭遮罩层
        if(res.config.isShowLoading){
            console.log(
                "要执行关闭遮罩"
            )
            setTimeout(() => {
                hideLoading();
            },800)
        }
        // console.log(`res--->${JSON.stringify(res)}`)
        const { code, flag, message } = res.data;
        if (flag === true) {
            // if (config.isShowMessage)
            if(res.config.isShowMessage){
                Message.success(message);
            }
             // 成功返回信息
            return res; // 返回数据
        } else {
            // 根据需要处理不同状态码的错误
            switch (code) {
                case 401:
                    // 未登录，跳转登录页
                    Message.error(message); // 返回错误信息并登录
                    router.push('/'); // 跳转到登陆界面
                    return ; // 返回数据
                default:
                    // 其他错误，显示通用错误信息
                    Message.error(message);
                    return res; // 返回数据
            }
            // return Promise.reject(new Error(message || '服务器返回错误'));
        }
    },
    error => {
        //0.8s后关闭遮罩层
        setTimeout(() => {
            hideLoading();
        },800)
        console.error('响应拦截器错误:', error);
        // 网络错误或其他错误
        Message.error('网络请求出现问题，请检查您的网络');
        return Promise.reject(error);
    }
);

export default service;