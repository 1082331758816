import {Loading} from 'element-ui'; //遮罩层
// 创建一个全局的loading实例
let loadingInstance;
function hideLoading() {
    loadingInstance.close();
}

function showLoading() {
    loadingInstance = Loading.service({
        lock: true, // 禁止滚动
        text: '数据加载中...', // 自定义加载提示文字
        background: 'rgba(0, 0, 0, 0.2)', // 背景颜色
    });
}
export { showLoading, hideLoading };
