import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axiosInstance from './services/config/axios/AxiosConfig'; // 导入封装好的axios实例
import './assets/svg/iconfont.js' //引入阿里巴巴图标库js
import './assets/svg/iconfont.css' //引入阿里巴巴图标库css
import VueCoreVideoPlayer from 'vue-core-video-player'
// import 'element-ui/lib/theme-chalk/base.css';// fade/zoom 等
// // collapse 展开折叠
// import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';
Vue.prototype.$http = axiosInstance;
Vue.config.productionTip = false
Vue.use(ElementUI)
    .use(VueCoreVideoPlayer, {
        lang: 'zh-CN'
    });
// Vue.component(CollapseTransition.name, CollapseTransition)
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
