<template>

  <div class="home">
    <!--    左侧-->
    <div class="left">
      <el-row class="tac">
        <el-col :span="24">
          <!--          background-color="#545c64"-->
          <!--          text-color="#fff"-->
          <!--          active-text-color="#ffd04b"-->
          <el-menu
              :router="true"
              :default-active="routerPath"
              class="el-menu-vertical-demo"
              @open="handleOpen"
              @close="handleClose">
            <el-submenu index="1">
              <template slot="title">
                <i class="el-icon-location"></i>
                <span>管理员中心</span>
              </template>
              <el-menu-item index="/Home/AdminInfo">管理员信息</el-menu-item>
            </el-submenu>
            <el-submenu index="2">
              <template slot="title">
                <i class="el-icon-location"></i>
                <span>用户中心</span>
              </template>
              <el-menu-item index="/Home/DeceasedInfo">逝者信息</el-menu-item>
              <el-menu-item index="/Home/deletedDeceased">即将被删除的逝者</el-menu-item>
            </el-submenu>
            <el-submenu index="3">
              <template slot="title">
                <i class="el-icon-setting"></i>
                <span>自助工具</span>
              </template>
              <el-menu-item index="">
              </el-menu-item>
            </el-submenu>
          </el-menu>
        </el-col>
      </el-row>
    </div>
    <!--    右侧-->
    <div class="right">

      <!--      主区域-->
      <div class="main">
        <router-view></router-view>
      </div>
      <!--      底部备案栏-->
      <div class="head">
        <div class="logo">
          <!--          <img src="@/assets/imges/logo.jpg" alt="">-->
        </div>
        <div class="copyright">
          <!--          文字区域-->
          <div class="text">
            <el-descriptions title="" direction="vertical" :column="2">
              <el-descriptions-item label="所属企业">
                随州千古留名网络科技有限公司
              </el-descriptions-item>
              <el-descriptions-item label="备案号">
                鄂ICP备2024057129号-1
              </el-descriptions-item>
            </el-descriptions>
          </div>
        </div>
      </div>
    </div>


  </div>

</template>

<script>
export default {
  // 组件名称
  name: 'HomeView',
  // 数据属性声明
  data() {
    return {
      // 这里存放数据属性
      routerPath: this.$route.path//导航栏默认显示当前路径
    };
  },
  // 方法集合
  methods: {
    // 组件内部的方法定义
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
  },

  // 生命周期钩子 - 挂载后，组件已经渲染到DOM中
  mounted() {
    // 此时组件已经挂载到DOM上，可以进行DOM操作
  },

  // 计算属性声明
  computed: {
    // 可以在这里定义计算属性
  },
  // 监视属性变化
  watch: {
    // 监视的数据属性以及对应的处理函数
  },
  // 生命周期钩子 - 组件创建之前
  beforeCreate() {
    // 此阶段尚未初始化数据，无法访问到data、computed等属性
  },
  // 生命周期钩子 - 组件创建完成，数据观测(data observer) 和 event/watcher 事件配置之前
  created() {
    // 此时可以访问到data、computed等属性，但DOM还未生成
  },
  // 生命周期钩子 - 挂载前，渲染函数首次被调用
  beforeMount() {
    // 此时已经完成了模板编译，但是还没有挂载到DOM上
  },
  // 更新相关钩子
  beforeUpdate() {
    // 数据更新时调用，发生在虚拟DOM重新渲染和打补丁之前
  },
  updated() {
    // 数据更新导致的DOM更新完成后调用，但不应在此方法中修改状态，这可能陷入死循环
  },
  // 生命周期钩子 - 实例销毁之前
  beforeDestroy() {
    // 可以在此处执行清理工作，如取消定时器、解绑全局事件等
  },
  // 生命周期钩子 - 实例销毁之后
  destroyed() {
    // 组件已经被销毁，清理工作在此时已完成
  }

};
</script>

<style scoped lang="less">
/* 组件专属样式 */
/* 使用scoped属性确保样式只作用于当前组件 */

@leftWidth: 15%; //左侧导航栏宽度
@bottom: 10%; //顶部的高度
.home {
  //弹性布局
  display: flex;
  width: 100%;
  height: 100%;

  .right {
    width: 100% - @leftWidth;
    height: 100%;

    .head {
      width: 100%;
      height: @bottom;
      display: flex;
      //横向居中
      .logo {
        width: 20%;
        height: 100%;
        background-image: url("@/assets/imges/logo.jpg");
        background-size: 100% 100%;
        background-repeat: no-repeat; //*图片完全充满div*
      }

      .copyright {
        width: 80%;
        height: 100%;

        .text {
          margin-left: 5%;
          width: 80%;
        }
      }
    }

    .main {
      width: 100%;
      height: 100% - @bottom;
      //background-color: green;
    }
  }

  //导航栏
  .left {
    width: @leftWidth;
    height: 100%;

    .el-menu {
      height: 100vh;
    }

    //background-color: blue;
  }

}

</style>