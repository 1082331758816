import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo: {},
  },
  getters: {
  },
  mutations: {
    /**
     * 设置当前登录的用户信息
     * @param state
     * @param newUserInfo
     * @constructor
     */
    SET_USER_INFO(state, newUserInfo) {
      state.userInfo = newUserInfo; // 假设我们有一个queryResult的state用来存放查询结果
    },

  },
  actions: {
  },
  modules: {
  }
})
